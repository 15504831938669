import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function SolutionAKN() {
	
	return (
		<Article
			title='AKN (Acne Keloidalis Nuchae) - Acne behind neck'
			takeaway_title='Measures to cure AKN'
			page_id='Solution - AKN'
			read_time='7 mins'
			author='Brij Bhushan'
			image1='/inflamed-follicle.jpg'
			image1_description='In AKN, inflammation is in the follicle rather than the skin surface'
			image2='/hairfollicle.jpg'
			image2_description='Anatomy of hair follicle'
			toggle_article='Blog'
			toggle_url='/article/blog_akn'
			related_article='Blog : AKN'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
			<div className='article_container'>
					<p>
						Acne Keloidalis Nuchae literally means ‘scarring acne of the neck’. AKN is the result of inflammation in the follicle (referred as folliculitis). It is reported to be prevalent in African-Americans. However, it has been reported also in Hispanics and Asians. From dermatologists practising in India I understand that AKN is not too rare in India as well. It is believed that people with thick skin, thick hair or living in hot climates are more likely to get the disease. <span className="super">[1], [2]</span>
					</p>
					<p>
						In this skin disease, bumps appear on the back of the neck near or above the hairline. The problem starts with inflammation in the follicle wall, which results in its weakening. With weakening of the follicle, the hair shaft starts poking into surrounding skin tissues. This causes inflammation of the area surrounding the hair shaft and small acne-like eruption appears on the skin. The immune response to the inflammation, deposits fibrous connective tissue. This deposit is called fibrosis. The fibrosis is responsible for the dark bumps created over the follicle. The fibrosis obstructs the cavity for the hair shaft and this results in a perpetual inflammation and scarring around the follicle as the hair shaft continues to grow. <span className="super">[3]</span>
					</p>
					<p>
						The bumps are itchy & the condition aggravates with following actions: <span className="super">[4]</span>
						<ol className='article_list'>
							<li>Regular rubbing of shirt collar (or helmet or cap) on the affected neck area</li>
    						<li>Close shave of the hair shafts in the affected neck area</li>
    						<li>Scratching of the affected neck area</li>
    						<li>Sweating & other conditions that aggravate inflammation</li>
    					</ol>
					</p>
					<p>
						The root cause of this problem is inflammation and since these actions aggravate the skin inflammation, therefore they aggravate the problem. Also, it has been found that the inflammation creates cracks in the skin and often a secondary infection finds its way into the skin through the cracks. This leads to bacterial  infection, in addition to the inflammatory condition. The bacteria responsible for the secondary infection is usually, Staphylococcus aureus. This bacteria is often found on human skin & it acts as an opportunist when it finds a crack in the skin. <span className="super">[4]</span>
					</p>
					<p>
						It is not known why mostly African-Americans suffer from AKN. The key trigger of the disease is also unknown. Anyway, let's focus on how to cure it. To cure the AKN, one has to follow a 4-pronged approach. First is to stop the actions that aggravate the inflammation. Second is to build a strong immunity. Third is to reduce inflammation on the skin. Fourth is to trigger autophagy of the body. <span className="super">[4], [5], [6]</span>
					</p>
					<h3 className='article_heading'>Stop the actions that aggravate the inflammation</h3>
					<p>
						The actions that aggravate inflammation are listed above. Don’t wear a tight collar shirt, esp. with a tie. Avoid wearing a cap, a helmet or anything that presses/rubs on the affected area of the neck. Never shave hair at the affected area, nor clip them too short. Never scratch the affected area with your nails. (Bang your forehead with your fist if you have to, to suppress the urge to scratch.) Avoid sweating & exposing the affected area to direct heat. <span className="super">[4]</span>
					</p>
					<p>
						Also avoid those hair care products which can cause skin irritation. For details of such products refer to the article <Link to='/article/soln_haircare'>here</Link>.
					</p>
					<h3 className='article_heading'>Build a strong immunity</h3>
					<p>
						Strong immunity comes from a healthy liver. For a healthy liver, one has to take the following measures:
						<ol className='article_list'>
							<li>No refined white sugar</li>
    						<li>Not drinking water before & after meals</li>
    						<li>No snacking between meals</li>
    						<li>Properly chewing the food</li>
    						<li>No alcohol</li>
    						<li>No fruit juice</li>
    						<li>Good sleep at night</li>
    						<li>Regular exercise</li>
    					</ol>
					</p>
					<p>
						These measures are the same as those for weight loss because obesity is just a symptom of a weak liver. One has to take care of the liver to strengthen immunity and weight correction happens automatically when you are successful in that. For details on these measures refer to the article <Link to='/article/soln_obesity'>here</Link>.
					</p>
					<h3 className='article_heading'>Reduce skin inflammation</h3>
					<p>
						Most of the measures required to reduce skin inflammation overlap with the measure for strong immunity. The additional one is to consume milk in the correct way. So, either don’t consume milk or strictly follow these guidelines:
						<ol className='article_list'>
							<li>Treat milk as a complete meal (Don’t drink milk with breakfast or any other meal)</li>
    						<li>Don’t eat anything 2 hrs before & 2 hrs after having milk</li>
    						<li>Consume milk in moderation (1 glass in a day)</li>
    						<li>Prefer Cow’s A2 milk</li>
    						<li>Don’t eat milk or cream with salt</li>
    					</ol>
					</p>
					<p>
						These measures are the same as those explained in the article on curing the acne problem. For details on these guidelines refer to the article <Link to='/article/soln_faceacne'>here</Link>.
					</p>
					<h3 className='article_heading'>Trigger autophagy of the body</h3>
					<p>
						Autophagy is a mechanism of the body that recycles its misfolded proteins. It is one of the capabilities of the human body that allows it to survive on unused resources available within the body, when it is short of supply from food intake. When used wisely, autophagy can help get rid of the ugly fibrosis deposits in the affected area. 
					</p>
					<p>
						To trigger autophagy one has to daily observe fasting for 14 hrs. Here, fasting refers to not eating anything during the period. One can consume only water during the period. This may not be as difficult as it may sound because the fasting can be done while you are sleeping. If you eat dinner at 8 PM and then have breakfast at 10 AM the next morning, then it is sufficient to trigger the autophagy. If you follow this routine for a few months, then the ugly scars of AKN will disappear automatically without any additional effort. <span className="super">[6]</span>
					</p>
					<h3 className='article_heading'>Conclusion</h3>
					<p>
						It is still unknown why few people get this skin condition and others don’t. However, here we focus on what aggravates the condition if you have it, and how it can be cured. Emphasis of the cure is on not aggravating it further, strengthening immunity, reducing inflammation and utilizing autophagy.
					</p>
					<p>
						There are few treatments which are often discussed for the cure of AKN, like applying topical creams, antibiotics, cryotherapy and laser treatment. One can refer to my blog <Link to='/article/blog_akn'>here</Link>, to learn about my experiences with them and how the principles mentioned here helped me cure the disease. <span className="super">[3]</span>

					</p>
					<h3 className='article_heading'>References</h3>
					<ol className="reference_list">
						<li>
							Maranda, E. L., Simmons, B. J., Nguyen, A. H., et al. (2016). Treatment of Acne Keloidalis Nuchae: A Systematic Review of the Literature. Dermatology and therapy, 6(3), 363–378. <Link to='https://doi.org/10.1007/s13555-016-0134-5'>https://doi.org/10.1007/s13555-016-0134-5</Link>
						</li>
						<li>
							Medigoo. Acne Keloidalis Nuchae. <Link to='https://www.medigoo.com/articles/acne-keloidalis-nuchae/'>https://www.medigoo.com/articles/acne-keloidalis-nuchae/</Link>
						</li>
						<li>
							Elizabeth K Satter, Philip R Letada. Acne Keloidalis Nuchae (AKN). Medscape. Nov 13, 2020. <Link to='https://emedicine.medscape.com/article/1072149-overview'>https://emedicine.medscape.com/article/1072149-overview</Link> , <Link to='https://emedicine.medscape.com/article/1072149-treatment'>https://emedicine.medscape.com/article/1072149-treatment</Link>
						</li>
						<li>
							Ogunbiyi A. Acne keloidalis nuchae: prevalence, impact, and management challenges. Clinical, Cosmetic and Investigational Dermatology. 2016;9:483–9. <Link to='https://doi.org/10.2147/CCID.S99225'>https://doi.org/10.2147/CCID.S99225</Link>
						</li>
						<li>
							Kiyong Na, Sang Ho Oh, Sang Kyum Kim. Acne keloidalis nuchae in Asian: A single institutional experience. PLOS ONE 12(12): December 14, 2017. <Link to='https://doi.org/10.1371/journal.pone.0189790'>https://doi.org/10.1371/journal.pone.0189790</Link>
						</li>
						<li>
							Ravanan P, Srikumar IF, Talwar P. Autophagy: The spotlight for cellular stress responses. Life Sciences. Nov 1, 2017;188:53-67. <Link to='https://www.researchgate.net/publication/319438411_Autophagy_The_spotlight_for_cellular_stress_responses'>https://www.researchgate.net/publication/319438411_Autophagy_The_spotlight_for_cellular_stress_responses</Link>
						</li>

					</ol>
				
			</div>
			
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: "Don't scratch the acne & avoid rubbing on them",
	},
	{
		takeaway: "Don't shave the hair in the affected area",
	},
	{
		takeaway: "Don't consume sugar & products that contain sugar",
	},
	{
		takeaway: "Don't drink water before & after meals",
	},
	{
		takeaway: "Don't eat snacks between meals",
	},
	{
		takeaway: "Chew your food properly",
	},
	{
		takeaway: "Don't consume alcohol",
	},
	{
		takeaway: "Don't drink fruit juice",
	},
	{
		takeaway: "Have sufficient sleep at night",
	},
	{
		takeaway: "Exercise regularly",
	},
	{
		takeaway: "Either don't drink milk or consume it in the correct way",
	},
	{
		takeaway: "Daily fast for 14 hrs (No eating from 8 PM to 10 AM)",
	},
];

const RelatedArticles = [
	{
		title: 'Blog - AKN',
		path: '/article/blog_akn',
	},
	{
		title: 'Solution - Haircare',
		path: '/article/soln_haircare',
	},
	{
		title: 'Solution - Obesity',
		path: '/article/soln_obesity',
	},
	{
		title: 'Solution - Face Acne',
		path: '/article/soln_faceacne',
	},
];

